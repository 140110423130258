import React from 'react';

export default function Button(props) {
  let cl = '';
  let disabledMsg = props.disabledMsg ? props.disabledMsg : 'Please Wait...';
  let color = props.color ? props.color : null;

  if (color) {
    cl += color + '-button ';
  }
  if (props.alternate === true) {
    cl += 'alternate ';
  }
  if (props.active === true) {
    cl += 'active ';
  }
  if (props.large === true) {
    cl += 'button-large ';
  }
  if (props.small === true) {
    cl += 'button-small ';
  }
  if (props.link === true) {
    cl += 'link ';
  }
  if (props.className) {
    cl += props.className;
  }

  function cb(e) {
    if (!props.submit) {
      e.preventDefault();
    }

    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <button className={`button ${cl}`} onClick={(e) => cb(e)} disabled={props.disabled} style={props.style}>
      {props.disabled ? disabledMsg : props.children ? props.children : props.label}
    </button>
  );
}
