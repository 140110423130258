import React, { useState } from 'react';
import { Link } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleUser, faXmark } from '@fortawesome/free-solid-svg-icons';

import Button from './Button';

import Logo from '../images/logo.svg';
import wave from '../images/wave.svg';

export default function Header({ title, children, content = null }) {
  const siteName = 'The Little Dolphin Academy';
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header>
      <title>
        {title} - {siteName}
      </title>
      <div className={`header-container ${showMenu === true ? 'show-menu' : ''}`}>
        <div className="header-logo">
          <Link to="/">
            <img src={Logo} alt={siteName} draggable="false" />
          </Link>
        </div>
        <div className="header-menu">
          <nav>
            <Link to="/about/" activeClassName="active">
              About Us
            </Link>
            <Link to="/lessons/children/" activeClassName="active" partiallyActive={true}>
              Swimming Lessons
            </Link>
            <Link to="/locations/" activeClassName="active" partiallyActive={true}>
              Swimming Pools
            </Link>
            <Link to="/testimonials/" activeClassName="active">
              Testimonials
            </Link>
            <Link to="/contact/" activeClassName="active">
              Get In Touch
            </Link>
          </nav>
        </div>
        <h1 className="page-title">
          <span>{title}</span>
        </h1>
        <div className="header-user">
          <FontAwesomeIcon icon={faCircleUser} /> Sign In
        </div>
        <div className="mobile-menu-link">
          <Button link onClick={() => setShowMenu(!showMenu)}>
            <FontAwesomeIcon icon={showMenu === true ? faXmark : faBars} />
          </Button>
        </div>
      </div>
      {content !== null ? content : null}
      <img className="header-wave" src={wave} alt="wave" draggable="false" />
    </header>
  );
}
