import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faCheckCircle, faCalendar } from '@fortawesome/free-solid-svg-icons';

const Locations = () => {
  const locations = [
    {
      name: 'Holyrood Secondary School',
      image: (
        <StaticImage
          src="../images/pools/one.webp"
          alt="Swimming Pool"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'bottom',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Preschool, Children and Adult Lessons',
        },
        {
          name: 'Group, Private 1-1 and Private 1-2 Available',
        },
        {
          name: '30 Minute Lessons',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      days: 'Monday, Thursday, Sunday',
      location: 'JMG Swim School - Holyrood Secondary School, 100 Dixon Rd, Glasgow G42 8AU',
    },
    {
      name: "St Paul's High",
      image: (
        <StaticImage
          src="../images/pools/one.webp"
          alt="Swimming Pool"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'bottom',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Preschool, Children and Adult Lessons',
        },
        {
          name: 'Group, Private 1-1 and Private 1-2 Available',
        },
        {
          name: '30 Minute Lessons',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      days: 'Tuesday, Friday',
      location: "St Paul's High, address, here",
    },
    {
      name: 'Blantyre',
      image: (
        <StaticImage
          src="../images/pools/one.webp"
          alt="Swimming Pool"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'bottom',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Preschool, Children and Adult Lessons',
        },
        {
          name: 'Group, Private 1-1 and Private 1-2 Available',
        },
        {
          name: '30 Minute Lessons',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      days: 'Monday, Tuesday',
      location: 'Campsie View, Blantyre, address, here',
    },
  ];

  return (
    <Layout styles="locations" title="Swimming Pools">
      <div className="location-content">
        {locations.length > 0
          ? locations.map((locat, index) => (
              <div key={`feature-location-${index}`} className="location-container">
                <div className="location-name">
                  <Link to="/locations/placeholder">{locat.name}</Link>
                </div>
                <div className="location-image">{locat.image}</div>
                <div className="location-data">
                  <div className="location-features">
                    {locat.features.length > 0
                      ? locat.features.map((feature, featIndex) => (
                          <div key={`feature-key-${featIndex}`} className="location-feature">
                            <FontAwesomeIcon icon={feature.icon ? feature.icon : faCheckCircle} />
                            {feature.name}
                          </div>
                        ))
                      : 'No features'}
                  </div>
                  <div className="location-location">
                    <FontAwesomeIcon icon={faCalendar} /> {locat.days}
                  </div>
                  <div className="location-location">
                    <FontAwesomeIcon icon={faLocationDot} /> {locat.location}
                  </div>
                </div>
                <div className="location-buttons">
                  <Link to="/locations/placeholder" className="button blue-button">
                    More Details
                  </Link>
                </div>
              </div>
            ))
          : 'No Locations Available'}
      </div>
    </Layout>
  );
};

export default Locations;
