import React from 'react';
import '../styles/default.scss';
import Header from './Header';
import Footer from './Footer';

export default function Layout({ title, styles = '', content = null, children }) {
  return (
    <div className={`main-container ${styles}`}>
      <Header title={title} content={content} />
      <main>
        <div className="container">{children}</div>
      </main>
      <Footer />
    </div>
  );
}
