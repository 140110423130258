import React from 'react';
import { Link } from 'gatsby';

export default function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <div className="content-container">
          <div className="left-content">
            <div className="copyright">&copy; 2022 - The Little Dolphin Academy</div>
            <div className="footer-links">
              <Link href="/terms-and-conditions">Terms and Conditions</Link>
              <Link href="/privacy-policy">Privacy Policy</Link>
              <Link href="/cookie-policy">Cookie Policy</Link>
            </div>
          </div>
          <div className="bw-logo">
            <a href="https://bespokeweb.site">
              <img src="https://assets.bespokeweb.io/dark-logo.svg" alt="BespokeWeb.site" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
